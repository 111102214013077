import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import AddIcon from '@mui/icons-material/Add'
import Box from '@mui/material/Box'
import Fab from '@mui/material/Fab'

import ListItem from 'components/CommonListItem'
import { confirm } from 'components/Confirm'
import GroupFilter, { filter as filterWithGroup } from 'components/GroupFilter'
import { showSnackbar } from 'components/Snackbar'
import Sort from 'components/Sort'
import TagFilter, { filter as filterWithTags } from 'components/TagFilter'
import useLocalStorage from 'hooks/useLocalStorage'
import useAuth from 'lib/auth'
import { orderBy, updateDoc } from 'lib/firestore'
import { useDocs, where } from 'lib/firestore'

export default function ActivationTriggerList() {
  const [filterCondition, setFilterCondition] = useLocalStorage(
    'filterConditionActivationTriggerList',
    {
      group: '',
      tags: [],
    }
  )
  const navigate = useNavigate()
  const [tags, setTags] = useState(filterCondition.tags)
  const [group, setGroup] = useState(filterCondition.group)
  const [order, setOrder] = useState(['createdAt', 'desc'])
  const { items: groups } = useDocs('groups')
  const { user } = useAuth()
  const { items } = useDocs('activation-triggers', where('deleted', '==', false), orderBy(...order))

  const activationTriggers = useMemo(() => {
    let _items = [...items]
    _items = filterWithGroup(_items, group, user)
    _items = filterWithTags(_items, tags)
    return _items
  }, [items, tags, group, user])

  const handleDuplicate = async item => {
    navigate('./new', { state: { item } })
  }

  const handleDelete = async item => {
    if (
      await confirm({
        title: 'Web 施策トリガーを削除してもよろしいですか？',
        body: '一度削除すると復元することはできません。',
      })
    ) {
      updateDoc(`activation-triggers/${item.id}`, { deleted: true }).catch(() =>
        showSnackbar('Web 施策トリガーの削除に失敗しました')
      )
    }
  }

  const handleChangeGroup = newValue => {
    setGroup(newValue)
    setFilterCondition({ ...filterCondition, group: newValue })
  }

  const handleChangeTags = newValue => {
    setTags(newValue)
    setFilterCondition({ ...filterCondition, tags: newValue })
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', gap: 2, mb: 1 }}>
        <GroupFilter value={group} onChange={handleChangeGroup} />
        <TagFilter value={tags} onChange={handleChangeTags} />
        <Sort value={order} onChange={setOrder} />
      </Box>
      <Box>
        {activationTriggers.map(x => (
          <ListItem
            key={x.id}
            item={x}
            group={groups.find(y => y.id === x.group)}
            editable={!x.group || user.groups?.includes(x.group)}
            onClick={() => navigate(`./${x.id}`)}
            onDelete={() => handleDelete(x)}
            onDuplicate={() => handleDuplicate(x)}
          />
        ))}
      </Box>
      <Fab onClick={() => navigate('./new')}>
        <AddIcon />
      </Fab>
    </Box>
  )
}
