import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import FormHelperText from '@mui/material/FormHelperText'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { Form, Select, Checkbox, SubmitButton } from 'components/Form'

export default function PublishSettingDialog({ data, onSubmit, onClose }) {
  const [open, setOpen] = useState(false)
  const form = useForm()

  useEffect(() => {
    setOpen(true)
  }, [data])

  return (
    <Dialog open={open} onClose={() => setOpen(false)} TransitionProps={{ onExited: onClose }}>
      <Form form={form} onSubmit={onSubmit}>
        <DialogTitle>テスト配信設定</DialogTitle>
        <Divider />
        <DialogContent>
          <Stack spacing={2}>
            <Select name="contentIndex" label="バリエーション" fullWidth defaultValue={0}>
              {data.contents.map((content, index) => (
                <MenuItem key={content.id} value={index} disabled={content.subject === ''}>
                  {content.name}
                  <Typography sx={{ pl: 2 }} variant="caption">
                    {Boolean(content.subject) ? content.subject : 'メール件名を指定してください'}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
            <Checkbox
              name="addTestPrefix"
              label="件名にテスト送信を示す **TEST** を付与する"
              defaultValue={true}
            />
            {form.formState.errors.network && (
              <FormHelperText error>{form.formState.errors.network.message}</FormHelperText>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={() => setOpen(false)}>
            キャンセル
          </Button>
          <SubmitButton color="inherit" sx={{ fontWeight: 'bold' }}>
            送信する
          </SubmitButton>
        </DialogActions>
      </Form>
    </Dialog>
  )
}
