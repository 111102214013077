import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Routes, Route, Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import AllInboxIcon from '@mui/icons-material/AllInboxTwoTone'
import SaveIcon from '@mui/icons-material/SaveTwoTone'
import SendIcon from '@mui/icons-material/SendTwoTone'
import WarningIcon from '@mui/icons-material/WarningTwoTone'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import Dialog from '@mui/material/Dialog'
import Divider from '@mui/material/Divider'
import FormHelperText from '@mui/material/FormHelperText'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'

import DetailPageMenuList, { DetailPageMenuButton } from 'components/DetailPageMenuList'
import { Autocomplete, Checkbox, Form, Select, SubmitButton, TextField } from 'components/Form'
import { filter } from 'components/GroupFilter'
import Loading from 'components/Loading'
import { prompt } from 'components/Prompt'
import PublishSettingDialog from 'components/PublishSettingDialog'
import ContentSelectionDialog from './ContentSelectionDialog'
import { showSnackbar } from 'components/Snackbar'
import useAuth from 'lib/auth'
import {
  docRef,
  generateId,
  getDoc,
  getDocs,
  serverTimestamp,
  useDocs,
  writeBatch,
  Timestamp,
} from 'lib/firestore'
import { httpsCallable } from 'lib/functions'
import { isNaturalNumber } from 'utils'

import DeliveryHistory from './DeliveryHistory'
import ContentFieldArray, { defaultContents } from './ContentFieldArray'
import ErrorDialog from './ErrorDialog'
import SegmentField from './SegmentField'

export const DELIVERY_STATUS = Object.freeze({
  PENDING: 'PENDING', // 処理待ち
  READY: 'READY', // 実行待ち
  PROCESSING: 'PROCESSING', // 送信中
  RETRY: 'RETRY', // リトライ
  CONNECTED_TO_CGW: 'CONNECTED_TO_CGW', // CGW に送信済み
  FETCHING_CGW_STATUS: 'FETCHING_CGW_STATUS', // CGW のステータス取得中
  SUCCESS: 'SUCCESS', // 成功
  ERROR: 'ERROR', // エラー
})

const makeCopy = item => {
  return {
    ...item,
    id: null,
    name: `${item.name} のコピー`,
    latestDelivery: null,
    approval: { group: false, admin: false },
  }
}

const formatForDb = item => {
  const contents = item.contents.map(x => {
    const { id, ...values } = x
    return { id, values }
  })
  item.contents = item.contents.map(x => x.id)
  item.sendAt = Timestamp.fromDate(new Date(item.sendAt))
  return { parentDocValue: item, contents }
}

const parseFromDb = (item, contents) => {
  item.sendAt = dayjs(item.sendAt).format('YYYY-MM-DDTHH:mm')
  item.contents = contents.sort((a, b) => item.contents.indexOf(a.id) - item.contents.indexOf(b.id))
  return item
}

export default function TargetMailDetail() {
  const [open, setOpen] = useState(false)
  const [openErrorDialog, setOpenErrorDialog] = useState(false)
  const params = useParams()
  const [item, setItem] = useState(null)
  const { items } = useDocs('target-mails')
  const { user } = useAuth()
  const { items: groups } = useDocs('groups')
  const fromOptions = groups
    .filter(group => user.groups.includes(group.id))
    .flatMap(group =>
      (group.sendFromMails || []).map(x => ({
        name: x.name,
        email: x.email,
        groupId: group.id,
      }))
    )
  const form = useForm({ defaultValues: { contents: defaultContents } })
  const contents = form.watch('contents')
  const navigate = useNavigate()
  const data = item || {}
  const approved = (data.approval?.admin || data.approval?.group) ?? false
  const location = useLocation()

  useEffect(() => {
    setOpen(true)
  }, [])

  useEffect(() => {
    async function fetchData() {
      if (!params.id) return
      const item = await getDoc(`target-mails/${params.id}`)
      const contents = await getDocs(`target-mails/${params.id}/contents`)
      const parsedItem = parseFromDb(item, contents)
      setItem(parsedItem)
      form.reset(parsedItem)
    }
    fetchData()
  }, [params.id, form])

  // 複製時
  useEffect(() => {
    async function fetchData() {
      if (location.state?.item) {
        const item = await getDoc(`target-mails/${location.state.item.id}`)
        const contents = await getDocs(`target-mails/${item.id}/contents`)
        const x = parseFromDb(makeCopy(item), contents)
        // sub collection の id 重複を回避
        x.contents = x.contents.map(x => ({ ...x, id: generateId() }))
        setItem(x)
        form.reset(x)
      }
    }
    fetchData()
  }, [location.state, form])

  useEffect(() => {
    if (contents.length === 1) {
      form.setValue('proportion', 100)
    }
  }, [contents.length, form])

  const handleUpdate = async values => {
    const settings = await prompt(PublishSettingDialog, data)
    if (!settings) return

    const newItem = {
      ...values,
      ...settings,
      updatedAt: serverTimestamp(),
    }

    const saveDocument = async () => {
      const batch = writeBatch()
      const { parentDocValue, contents } = formatForDb(newItem)
      batch.update(docRef(`target-mails/${data.id}`), parentDocValue)
      // 削除されたバリエーションの物理削除
      data.contents.forEach(({ id, values }) => {
        batch.delete(docRef(`target-mails/${data.id}/contents/${id}`))
      })
      contents.forEach(({ id, values }) => {
        batch.set(docRef(`target-mails/${data.id}/contents/${id}`), {
          ...values,
          parentId: data.id,
        })
      })
      await batch.commit()
    }

    return saveDocument()
      .then(() => setOpen(false))
      .catch(error => form.setError('network', { message: error.message }))
  }

  const handleAdd = async values => {
    const settings = await prompt(PublishSettingDialog)
    if (!settings) return

    const newItem = {
      ...values,
      ...settings,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      createdBy: user.id,
      deleted: false,
    }

    const saveDocument = async () => {
      const batch = writeBatch()
      const { parentDocValue, contents } = formatForDb(newItem)
      const docId = generateId('target-mails')
      batch.set(docRef(`target-mails/${docId}`), parentDocValue)
      contents.forEach(({ id, values }) => {
        batch.set(docRef(`target-mails/${docId}/contents/${id}`), { ...values, parentId: docId })
      })
      await batch.commit()
    }

    return saveDocument()
      .then(() => setOpen(false))
      .catch(error => {
        console.log(error)
        form.setError('network', { message: error.message })
      })
  }

  const handleResend = async () => {
    setOpenErrorDialog(false)
    return httpsCallable('callable-targetmail-resend')(item)
      .then(res => {
        console.log(res)
        showSnackbar(`再送登録を完了しました。`)
      })
      .catch(e => showSnackbar(`再送登録に失敗しました。${e.message}`))
  }

  const handleSendTest = async values => {
    const settings = await prompt(ContentSelectionDialog, values)
    if (!settings) return

    return httpsCallable('callable-targetmail-sendtest')({
      item: values,
      id: data.id || 'new',
      contentIndex: settings.contentIndex,
      addTestPrefix: settings.addTestPrefix,
    })
      .then(res => {
        console.log(res)
        showSnackbar(`テスト送信に成功しました。`)
      })
      .catch(e => showSnackbar(`テスト送信に失敗しました。${e.message}`))
  }

  if (params.id && !data.id) {
    return <Loading disableShrink />
  }

  if (params.id && filter(items, '', user).every(x => x.id !== data.id)) {
    return <Navigate to="../" replace />
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => setOpen(false)}
      TransitionProps={{ onExited: () => navigate('/target-mails') }}
      sx={{ '& .MuiPaper-root': { bgcolor: 'background.default' } }}
    >
      <Form form={form}>
        <AppBar position="fixed" color="inherit" elevation={0}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={() => setOpen(false)}
              disabled={form.formState.isSubmitting}
              edge="start"
              sx={{ mr: 2 }}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              メール配信
            </Typography>
            <DetailPageMenuList item={item}>
              <DetailPageMenuButton
                startIcon={<AllInboxIcon size={12} />}
                onClick={() => navigate('./deliveries')}
                disabled={!Boolean(data.id)}
              >
                配信履歴
              </DetailPageMenuButton>
            </DetailPageMenuList>
            {item?.latestDelivery?.state === DELIVERY_STATUS.ERROR && (
              <ButtonBase sx={{ px: 2, py: 1, mr: 1 }} onClick={() => setOpenErrorDialog(true)}>
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                  <WarningIcon color="error" sx={{ fontSize: 16 }} />
                  <Typography sx={{ fontSize: 14, fontWeight: 'bold', color: 'error.main' }}>
                    送信に失敗しました
                  </Typography>
                </Box>
              </ButtonBase>
            )}
            <SubmitButton
              variant="text"
              sx={{ mr: 2 }}
              startIcon={<SendIcon size={12} color="#fff" />}
              onClick={form.handleSubmit(handleSendTest)}
              disabled={Boolean(data.group) && !user.groups.includes(data.group)}
            >
              テスト送信
            </SubmitButton>
            <SubmitButton
              startIcon={<SaveIcon size={12} color="#fff" />}
              onClick={form.handleSubmit(Boolean(data.id) ? handleUpdate : handleAdd)}
              disabled={Boolean(data.group) && !user.groups.includes(data.group)}
            >
              保存
            </SubmitButton>
          </Toolbar>
          <Divider />
        </AppBar>
        <Toolbar />
        <Stack spacing={3} sx={{ p: 5 }}>
          <TextField
            label="設定名"
            name="name"
            disabled={approved}
            rules={{
              required: true,
              maxLength: 100,
              validate: v => {
                if (items.filter(x => x.id !== data.id).some(x => x.name === v)) {
                  return '既に存在する名前です。'
                } else {
                  return true
                }
              },
            }}
            fullWidth
            placeholder="新しいメール"
            errorText="100 文字以内で入力してください"
          />
          <Stack spacing={2}>
            <Typography sx={{ fontWeight: 'bold', color: theme => theme.palette.grey['700'] }}>
              対象範囲
            </Typography>
            <SegmentField name="target" disabled={approved} />
          </Stack>
          <Stack spacing={2}>
            <Typography sx={{ fontWeight: 'bold', color: theme => theme.palette.grey['700'] }}>
              コンテンツ
            </Typography>
            <TextField
              label="デフォルトバリエーション（バリエーション1）の割合"
              type="number"
              name="proportion"
              disabled={contents.length === 1 || approved}
              defaultValue={100}
              fullWidth
              rules={{ required: true, validate: v => isNaturalNumber(v) && v > 0 && v <= 100 }}
              errorText="1 から 100 の整数で入力して下さい"
            />
            <ContentFieldArray name="contents" control={form.control} disabled={approved} />
            {form.formState.errors.network && (
              <FormHelperText error>{form.formState.errors.network.message}</FormHelperText>
            )}
          </Stack>
          <Stack spacing={2}>
            <Typography sx={{ fontWeight: 'bold', color: theme => theme.palette.grey['700'] }}>
              送信設定
            </Typography>
            <Autocomplete
              name="sendFrom"
              disabled={approved}
              label="送信元アドレス"
              options={fromOptions}
              optionKeys={['name', 'email']}
              rules={{ required: true }}
            />
            <Stack direction="row" alignItems="center" spacing={2}>
              <TextField
                label="日付"
                name="sendAt"
                disabled={approved}
                type="datetime-local"
                sx={{ width: 320 }}
                rules={{ required: true }}
                InputLabelProps={{ shrink: true }}
              />
              <Typography>にメールを送信する</Typography>
            </Stack>
            <Select
              sx={{ width: 320 }}
              name="priority"
              disabled={approved}
              label="配信優先度"
              defaultValue="normal"
            >
              <MenuItem value="normal">普通</MenuItem>
              <MenuItem value="high">優先</MenuItem>
              <MenuItem value="top">送信必須</MenuItem>
            </Select>
            <Stack sx={{ width: 280 }}>
              <Checkbox name="approval.group" label="原局として配信設定を完了" />
              <Checkbox
                name="approval.admin"
                label="管理者として配信を許可"
                disabled={!user.isAdmin}
              />
            </Stack>
          </Stack>
        </Stack>
      </Form>
      <Routes>
        <Route path="deliveries" element={<DeliveryHistory />} />
      </Routes>
      {openErrorDialog && (
        <ErrorDialog
          item={item.latestDelivery}
          onClick={handleResend}
          onClose={() => setOpenErrorDialog(false)}
        />
      )}
    </Dialog>
  )
}
