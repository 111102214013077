import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SaveIcon from '@mui/icons-material/SaveTwoTone'
import AppBar from '@mui/material/AppBar'
import Dialog from '@mui/material/Dialog'
import Divider from '@mui/material/Divider'
import FormHelperText from '@mui/material/FormHelperText'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'

import DetailPageMenuList from 'components/DetailPageMenuList'
import { Form, SubmitButton, TextField } from 'components/Form'
import { filter } from 'components/GroupFilter'
import Loading from 'components/Loading'
import { prompt } from 'components/Prompt'
import PublishSettingDialog from 'components/PublishSettingDialog'
import useAuth from 'lib/auth'
import { addDoc, updateDoc, useDoc, useDocs } from 'lib/firestore'

import ConditionFieldArray from './ConditionFieldArray'

const makeCopy = item => {
  return {
    ...item,
    id: null,
    name: `${item.name} のコピー`,
  }
}

export default function ActivationDetail() {
  const [open, setOpen] = useState(false)
  const params = useParams()
  const { item } = useDoc(`activation-triggers/${Boolean(params.id) ? params.id : ''}`)
  const { items } = useDocs('activation-triggers')
  const { user } = useAuth()
  const form = useForm()

  const navigate = useNavigate()
  const data = item || {}
  const location = useLocation()

  useEffect(() => {
    setOpen(true)
  }, [])

  useEffect(() => {
    if (item) {
      item.startAt = dayjs(item.startAt).format('YYYY-MM-DDTHH:mm')
      if (item.endAt) {
        item.endAt = dayjs(item.endAt).format('YYYY-MM-DDTHH:mm')
      }
      form.reset(item)
    }
  }, [item, form])

  useEffect(() => {
    if (location.state?.item) {
      const x = makeCopy(location.state.item)
      form.reset(x)
    }
  }, [location.state, form])

  const handleUpdate = async values => {
    const settings = await prompt(PublishSettingDialog, data)
    if (!settings) return

    return updateDoc(`activation-triggers/${data.id}`, { ...values, ...settings })
      .then(() => setOpen(false))
      .catch(error => form.setError('network', { message: error.message }))
  }

  const handleAdd = async values => {
    const settings = await prompt(PublishSettingDialog)
    if (!settings) return

    return addDoc('activation-triggers', {
      ...values,
      ...settings,
      createdBy: user.id,
      deleted: false,
    })
      .then(() => setOpen(false))
      .catch(error => {
        console.log(error)
        form.setError('network', { message: error.message })
      })
  }

  if (params.id && !data.id) {
    return <Loading disableShrink />
  }

  if (params.id && filter(items, '', user).every(x => x.id !== data.id)) {
    return <Navigate to="../" replace />
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => setOpen(false)}
      TransitionProps={{ onExited: () => navigate('../') }}
      sx={{ '& .MuiDialog-paper': { bgcolor: 'background.default' } }}
    >
      <Form form={form}>
        <AppBar position="fixed" color="inherit" elevation={0}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={() => setOpen(false)}
              disabled={form.formState.isSubmitting}
              edge="start"
              sx={{ mr: 2 }}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              Web 施策トリガー
            </Typography>
            <DetailPageMenuList item={item} />
            <SubmitButton
              startIcon={<SaveIcon size={12} color="#fff" />}
              onClick={form.handleSubmit(Boolean(data.id) ? handleUpdate : handleAdd)}
              disabled={Boolean(data.group) && !user.groups.includes(data.group)}
            >
              保存
            </SubmitButton>
          </Toolbar>
          <Divider />
        </AppBar>
        <Toolbar />
        <Stack spacing={2} sx={{ p: 5 }}>
          <TextField
            label="設定名"
            name="name"
            fullWidth
            rules={{
              required: true,
              maxLength: 100,
              validate: v => {
                if (items.filter(x => x.id !== data.id).some(x => x.name === v)) {
                  return '既に存在する名前です。'
                } else {
                  return true
                }
              },
            }}
            placeholder="新しい施策"
            errorText="100 文字以内で入力してください"
          />
          <ConditionFieldArray name="conditions" />

          {form.formState.errors.network && (
            <FormHelperText error>{form.formState.errors.network.message}</FormHelperText>
          )}
        </Stack>
      </Form>
    </Dialog>
  )
}
