import { useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

import AddIcon from '@mui/icons-material/Add'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Popover from '@mui/material/Popover'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { Select, TextField } from 'components/Form'

export default function ConditionFieldArray() {
  const { control, watch } = useFormContext()
  const { fields, insert, append, remove } = useFieldArray({ name: 'conditions', control })
  const conditions = watch('conditions')

  return (
    <Stack spacing={2}>
      {fields.map((item, index) => (
        <Box key={item.id} sx={{ display: 'flex', gap: 1 }}>
          <Box sx={{ width: 88, height: 54, display: 'flex', alignItems: 'center' }}>
            {index === 0 ? (
              <Typography sx={{ fontWeight: 'bold' }}>条件</Typography>
            ) : (
              <Typography>AND</Typography>
            )}
          </Box>
          <Box sx={{ flex: 1 }}>
            <Stack direction="row" spacing={2}>
              <Select
                label="フィールド"
                name={`conditions.${index}.field`}
                fullWidth
                sx={{ flex: 1 }}
                rules={{ required: true }}
              >
                <MenuItem value="url">URL</MenuItem>
                <MenuItem value="referrer">リファラー</MenuItem>
                <MenuItem value="userAgent">ユーザーエージェント</MenuItem>
                <MenuItem value="cookie">Cookie</MenuItem>
              </Select>
              <TextField
                name={`conditions.${index}.value`}
                label="正規表現パターン"
                fullWidth
                sx={{ flex: 1 }}
                rules={{ required: true }}
              />
            </Stack>
          </Box>
          <Box>
            <MoreVertButton
              options={[
                { label: '複製', onClick: () => insert(index + 1, conditions[index]) },
                { label: '削除', onClick: () => remove(index) },
              ]}
            />
          </Box>
        </Box>
      ))}
      <Box>
        <Button
          variant="text"
          startIcon={<AddIcon />}
          onClick={() => append({ field: '', value: '' })}
        >
          条件追加
        </Button>
      </Box>
    </Stack>
  )
}

function MoreVertButton({ options }) {
  const [anchorEl, setAnchorEl] = useState(null)

  return (
    <>
      <IconButton onClick={e => setAnchorEl(e.currentTarget)} sx={{ mt: '4px' }}>
        <MoreVertIcon />
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <List sx={{ minWidth: 80 }}>
          {options.map((x, i) => (
            <ListItem key={i} disablePadding>
              <ListItemButton
                onClick={() => {
                  x.onClick()
                  setAnchorEl(null)
                }}
              >
                <ListItemText primary={x.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  )
}
