import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SaveIcon from '@mui/icons-material/SaveTwoTone'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import Divider from '@mui/material/Divider'
import FormHelperText from '@mui/material/FormHelperText'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'

import DetailPageMenuList from 'components/DetailPageMenuList'
import { Autocomplete, Form, Select, SubmitButton, TextField } from 'components/Form'
import { filter } from 'components/GroupFilter'
import Loading from 'components/Loading'
import { prompt } from 'components/Prompt'
import PublishSettingDialog from 'components/PublishSettingDialog'
import useAuth from 'lib/auth'
import { addDoc, updateDoc, useDoc, useDocs, where } from 'lib/firestore'
import { isNaturalNumber } from 'utils'

import ContentFieldArray, { defaultContentValues, defaultContents } from './ContentFieldArray'
import SegmentField from './SegmentField'
import UserLabelField from './UserLabelField'

export const STATUS = Object.freeze({
  PENDING: 'PENDING', // 処理待ち
  READY: 'READY', // 実行待ち
  PROCESSING: 'PROCESSING', // 送信中
  RETRY: 'RETRY', // リトライ
  SUCCESS: 'SUCCESS', // 成功
  ERROR: 'ERROR', // エラー
})

const makeCopy = item => {
  return {
    ...item,
    id: null,
    name: `${item.name} のコピー`,
  }
}

export default function ActivationDetail() {
  const [open, setOpen] = useState(false)
  const params = useParams()
  const { item } = useDoc(`activations/${Boolean(params.id) ? params.id : ''}`)
  const { items } = useDocs('activations')
  const { items: triggers } = useDocs('activation-triggers', where('deleted', '==', false))
  const { user } = useAuth()
  const form = useForm({ defaultValues: { variations: defaultContents, triggers: [] } })
  const [variations, contentType] = form.watch(['variations', 'contentType'])

  const navigate = useNavigate()
  const data = item || {}
  const location = useLocation()

  useEffect(() => {
    setOpen(true)
  }, [])

  useEffect(() => {
    if (item) {
      item.startAt = dayjs(item.startAt).format('YYYY-MM-DDTHH:mm')
      if (item.endAt) {
        item.endAt = dayjs(item.endAt).format('YYYY-MM-DDTHH:mm')
      }
      form.reset(item)
    }
  }, [item, form])

  useEffect(() => {
    if (location.state?.item) {
      const x = makeCopy(location.state.item)
      x.startAt = dayjs(x.startAt).format('YYYY-MM-DDTHH:mm')
      if (x.endAt) {
        x.endAt = dayjs(x.endAt).format('YYYY-MM-DDTHH:mm')
      }
      form.reset(x)
    }
  }, [location.state, form])

  const handleUpdate = async values => {
    const settings = await prompt(PublishSettingDialog, data)
    if (!settings) return

    values.state = STATUS.PENDING

    values.startAt = new Date(values.startAt)
    if (values.endAt) {
      values.endAt = new Date(values.endAt)
    }

    values.variations.map(val => {
      if (values.contentType === 'application/json') {
        val.value = JSON.stringify(JSON.parse(val.value))
      }
      if (values.contentType === 'text/html') {
        val.value = val.value.replace(/[\r|\n|\r\n]|\s{2,}/g, ' ')
      }
      return val
    })

    return updateDoc(`activations/${data.id}`, { ...values, ...settings })
      .then(() => setOpen(false))
      .catch(error => form.setError('network', { message: error.message }))
  }

  const handleAdd = async values => {
    const settings = await prompt(PublishSettingDialog)
    if (!settings) return

    values.state = STATUS.PENDING

    values.startAt = new Date(values.startAt)
    if (values.endAt) {
      values.endAt = new Date(values.endAt)
    }

    values.variations.map(val => {
      if (values.contentType === 'application/json') {
        val.value = JSON.stringify(JSON.parse(val.value))
      }
      if (values.contentType === 'text/html') {
        val.value = val.value.replace(/[\r|\n|\r\n]|\s{2,}/g, ' ')
      }
      return val
    })

    return addDoc('activations', {
      ...values,
      ...settings,
      createdBy: user.id,
      deleted: false,
    })
      .then(() => setOpen(false))
      .catch(error => {
        console.log(error)
        form.setError('network', { message: error.message })
      })
  }

  const handleChangeLanguage = e => {
    const values = variations.map((v, i) => ({
      ...v,
      value: defaultContentValues[e.target.value],
    }))
    form.setValue('variations', values)
  }

  if (params.id && !data.id) {
    return <Loading disableShrink />
  }

  if (params.id && filter(items, '', user).every(x => x.id !== data.id)) {
    return <Navigate to="../" replace />
  }

  const frequencyType = form.watch('frequencyType')
  const segmentField = form.watch('segment')

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => setOpen(false)}
      TransitionProps={{ onExited: () => navigate('../') }}
      sx={{ '& .MuiDialog-paper': { bgcolor: 'background.default' } }}
    >
      <Form form={form}>
        <AppBar position="fixed" color="inherit" elevation={0}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={() => setOpen(false)}
              disabled={form.formState.isSubmitting}
              edge="start"
              sx={{ mr: 2 }}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              Web 施策 / API
            </Typography>
            <DetailPageMenuList item={item} />
            <SubmitButton
              startIcon={<SaveIcon size={12} color="#fff" />}
              onClick={form.handleSubmit(Boolean(data.id) ? handleUpdate : handleAdd)}
              disabled={Boolean(data.group) && !user.groups.includes(data.group)}
            >
              保存
            </SubmitButton>
          </Toolbar>
          <Divider />
        </AppBar>
        <Toolbar />
        {item?.startAt && !dayjs(item?.startAt).isAfter(dayjs(), 'day') && (
          <Alert severity="warning">
            <AlertTitle>既に施策が開始されています</AlertTitle>
            編集するとテスト結果に影響が出る恐れがあります。
          </Alert>
        )}
        <Stack spacing={2} sx={{ p: 5 }}>
          <TextField
            label="設定名"
            name="name"
            fullWidth
            rules={{
              required: true,
              maxLength: 100,
              validate: v => {
                if (items.filter(x => x.id !== data.id).some(x => x.name === v)) {
                  return '既に存在する名前です。'
                } else {
                  return true
                }
              },
            }}
            placeholder="新しい施策"
            errorText="100 文字以内で入力してください"
          />
          <Box sx={{ display: 'flex', gap: 2 }}>
            <TextField
              label="開始日"
              name="startAt"
              type="datetime-local"
              fullWidth
              defaultValue={dayjs().format('YYYY-MM-DDTHH:mm')}
              rules={{ required: true }}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="終了日"
              name="endAt"
              type="datetime-local"
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Box>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <SegmentField name="segment" />
            <Select
              name="reverseSegmentCondition"
              sx={{ width: '20%' }}
              label="一致・反転"
              defaultValue="include"
              disabled={segmentField === null}
            >
              <MenuItem value="include">に含まれる</MenuItem>
              <MenuItem value="exclude">に含まれない</MenuItem>
            </Select>
          </Box>
          <UserLabelField name="userLabel" />
          <Stack spacing={2} direction="row" alignItems="center">
            <Autocomplete
              name="triggers"
              label="施策トリガー"
              multiple={true}
              options={triggers || []}
              getOptionLabel={option => option.name}
              sx={{ flex: 1 }}
            />
            <Typography sx={{ pr: 2 }}>のいずれか</Typography>
          </Stack>
          <Select
            name="contentType"
            label="コンテンツ形式"
            defaultValue="text/html"
            onChange={handleChangeLanguage}
          >
            <MenuItem value="text/html">HTML</MenuItem>
            <MenuItem value="application/json">JSON</MenuItem>
            <MenuItem value="text/plain">テキスト</MenuItem>
          </Select>

          <Box sx={{ display: 'flex', gap: 2 }}>
            <Select name="frequencyType" label="フリークエンシー種別" defaultValue="none" fullWidth>
              <MenuItem value="none">制限しない</MenuItem>
              <MenuItem value="user">ユーザー毎に</MenuItem>
              <MenuItem value="hourly">1時間に</MenuItem>
              <MenuItem value="daily">1日に</MenuItem>
              <MenuItem value="weekly">週に</MenuItem>
              <MenuItem value="monthly">月に</MenuItem>
              <MenuItem value="yearly">年に</MenuItem>
            </Select>

            <TextField
              label="表示回数の上限"
              type="number"
              name="frequencyLimit"
              defaultValue={0}
              fullWidth
              rules={{ required: true, validate: v => isNaturalNumber(v) }}
              errorText="0以上の整数で入力して下さい"
              disabled={frequencyType === 'none'}
            />
          </Box>
          <TextField
            label="対象者（％）"
            type="number"
            name="proportion"
            defaultValue={100}
            fullWidth
            rules={{ required: true, validate: v => isNaturalNumber(v) && v > 0 && v <= 100 }}
            errorText="1 から 100 の整数で入力して下さい"
          />
          <ContentFieldArray name="variations" control={form.control} language={contentType} />
          {form.formState.errors.network && (
            <FormHelperText error>{form.formState.errors.network.message}</FormHelperText>
          )}
        </Stack>
      </Form>
    </Dialog>
  )
}
