import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Navigate, useNavigate, useParams } from 'react-router-dom'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SaveIcon from '@mui/icons-material/SaveTwoTone'
import AppBar from '@mui/material/AppBar'
import Dialog from '@mui/material/Dialog'
import Divider from '@mui/material/Divider'
import FormHelperText from '@mui/material/FormHelperText'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'

import { Form, SubmitButton, TextField } from 'components/Form'
import { filter } from 'components/GroupFilter'
import Loading from 'components/Loading'
import { showSnackbar } from 'components/Snackbar'
import useAuth from 'lib/auth'
import { addDoc, updateDoc, useDoc, useDocs } from 'lib/firestore'

import AggregationField from './AggregationField'
import GroupField from './GroupField'
import PreviewDialog from './PreviewDialog'

const buildQuery = value => {
  const convert = ({ conditions, operator }) => {
    return conditions.map(x => {
      switch (x.operator) {
        case 'must':
        case 'should':
          return { bool: { [x.operator]: convert(x) } }
        case 'match':
        case 'regexp':
        case 'prefix':
        case 'wildcard':
          return { [x.operator]: { [`${x.field.name}`]: x.value } }
        case 'exists':
          return { [x.operator]: { field: [x.field.name] } }
        case 'range.lt':
        case 'range.lte':
        case 'range.gte':
        case 'range.gt':
          const operator = x.operator.split('.')[1]
          return { range: { [`${x.field.name}`]: { [operator]: x.value } } }
        default:
          return null
      }
    })
  }
  // FIXME: ここの実装が冗長なのでシンプル化できないか検討したい
  return { query: { bool: { [value.operator]: convert(value) } } }
}

export default function EventTriggerDetail() {
  const [open, setOpen] = useState(false)
  const [previewContent, setPreviewContent] = useState(null)
  const params = useParams()
  const { item } = useDoc(`event-triggers/${Boolean(params.id) ? params.id : ''}`)
  const { items } = useDocs('event-triggers')
  const { user } = useAuth()
  const form = useForm({
    defaultValues: { name: '', conditions: { operator: 'must', conditions: [] } },
  })
  const navigate = useNavigate()
  const data = item || {}

  useEffect(() => {
    setOpen(true)
  }, [])

  useEffect(() => {
    if (item) {
      form.reset(item)
    }
  }, [item, form])

  const handleUpdate = async values => {
    return updateDoc(`event-triggers/${data.id}`, values)
      .then(() => setOpen(false))
      .catch(error => form.setError('network', { message: error.message }))
  }

  const handleAdd = async values => {
    return addDoc('event-triggers', {
      ...values,
      createdBy: user.id,
      deleted: false,
    })
      .then(() => setOpen(false))
      .catch(error => {
        console.log(error)
        form.setError('network', { message: error.message })
      })
  }

  const handlePreview = values => {
    setPreviewContent(JSON.stringify(buildQuery(values.conditions), null, 2))
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(previewContent).then(
      () => {
        showSnackbar('クリップボードにコピーしました')
      },
      () => {
        showSnackbar('クリップボードへのコピーに失敗しました')
      }
    )
  }

  if (params.id && !data.id) {
    return <Loading disableShrink />
  }

  if (params.id && filter(items, '', user).every(x => x.id !== data.id)) {
    return <Navigate to="../" replace />
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => setOpen(false)}
      TransitionProps={{ onExited: () => navigate('../') }}
      sx={{ '& .MuiDialog-paper': { bgcolor: 'background.default' } }}
    >
      <Form form={form}>
        <AppBar position="fixed" color="inherit" elevation={0}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={() => setOpen(false)}
              disabled={form.formState.isSubmitting}
              edge="start"
              sx={{ mr: 2 }}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" noWrap sx={{ flex: 1 }}>
              Atlas Event Trigger
            </Typography>
            <SubmitButton
              variant="text"
              sx={{ color: theme => theme.palette.primary.main, mr: 1 }}
              onClick={form.handleSubmit(handlePreview)}
            >
              プレビュー
            </SubmitButton>
            <SubmitButton
              startIcon={<SaveIcon size={12} color="#fff" />}
              onClick={form.handleSubmit(Boolean(data.id) ? handleUpdate : handleAdd)}
              disabled={Boolean(data.group) && !user.groups.includes(data.group)}
            >
              保存
            </SubmitButton>
          </Toolbar>
          <Divider />
        </AppBar>
        <Toolbar />
        <Stack sx={{ p: 5 }} spacing={2}>
          <TextField
            label="設定名"
            name="name"
            fullWidth
            rules={{
              required: true,
              maxLength: 100,
              validate: v => {
                if (items.filter(x => x.id !== data.id).some(x => x.name === v)) {
                  return '既に存在する名前です。'
                } else {
                  return true
                }
              },
            }}
            placeholder="新しいテスト"
            errorText="100 文字以内で入力してください"
          />
          <GroupField name="conditions" />
          <AggregationField name="aggregation.fields" />
          {form.formState.errors.network && (
            <FormHelperText error>{form.formState.errors.network.message}</FormHelperText>
          )}
        </Stack>
      </Form>
      {Boolean(previewContent) && (
        <PreviewDialog
          content={previewContent}
          onClick={handleCopy}
          onClose={() => setPreviewContent(null)}
        />
      )}
    </Dialog>
  )
}
